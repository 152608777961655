<template>
	<div class='footer-box'>
		<el-row class="footer">
			<el-col class="hidden-xs-only" :sm="24">
				<div class="footer-info">
					<el-row>
						<el-col :span="16">
							<ul class="f-r-b-s footer-lists">
								<li class="f-c-s-s flex1 footer-list-box" v-for="(list, n) in lists" :key="n+list.class_name">
									<div class="footer-list">{{list.class_name}}</div>
									<ul v-for="(nav, m) in list.children" :key="n+nav.class_name+m">
										<li class="footer-nav" @click="navTo(nav, m)">{{nav.class_name}}</li>
									</ul>
								</li>
							</ul>
						</el-col>
						<el-col v-if="false" class="footer-config" :span="8">
							<!-- 名称 -->
							<div class="footer-sub_name">{{config?.sub_name?.value||""}}</div>
							<!-- 地址 -->
							<div class="footer-address">集团地址：{{config?.address?.value||""}}</div>
							<!-- 电话 -->
							<div class="footer-phone">{{config?.phone?.value||""}}</div>
							<!-- 热线 -->
							<div class="footer-totline">{{config?.totline?.value||""}}</div>
							<!-- 邮箱 -->
							<div class="footer-email">{{config?.email?.value||""}}</div>
							<!-- 二维码 -->
							<div class="footer-img">
								<img :src="config?.wechat_official?.value||''" alt="">
							</div>
						</el-col>
						<el-col class="footer-config1 f-c-s-s" :span="8" v-if="config">
							<div class="img">
								<img :src="config?.logo?.value" alt="">
								<!-- <img src="@/assets/img/logo1.png" alt=""> -->
							</div>
							<!-- 邮箱 -->
							<div class="footer-email">邮箱：{{config?.email?.value||""}}</div>
							<!-- 地址 -->
							<div class="footer-address">集团地址：{{config?.address?.value||""}}</div>
							<div class="tip f-r-s-c">
								<div class="tip-img">
									<el-tooltip  placement="bottom" effect="light">
										<div slot="content" class="fixed_code f-c-c-c">
											<!-- <img src="https://resource.jxtszn.com/20230516/09432dd627480807fa34b96a52db4bd7.png" alt="">
											<p>拓世智能公众号</p> -->
											<img :src="config.wechat_official.value" alt="">
											<p style="text-align: center">{{config.wechat_official.remark}}</p>
										</div>
										<div class="tip-icon-text">
											<img src="@/assets/img/weixin_sign.png" alt="">
											<p>公众号</p>
										</div>
									</el-tooltip>
								</div>
								<div class="tip-img">
									<el-tooltip  placement="bottom" effect="light">
										<div slot="content" class="fixed_code f-c-c-c">
											<!-- <img src="https://resource.jxtszn.com/20230513/3a174e968ae173737e2dcf94bdbf34e2.jpg" alt="">
											<p>拓世智能视频号</p> -->
											
											<img :src="config.video_code.value" alt="">
											<p style="text-align: center">{{config.video_code.remark}}</p>
										</div>
										<div class="tip-icon-text">
											<img src="@/assets/img/shipin_sign.png" alt="">
											<p>视频号</p>
										</div>
									</el-tooltip>
								</div>
								<div class="tip-img">
									<el-tooltip placement="bottom" effect="light">
										<div slot="content" class="fixed_code f-c-c-c">
											<!-- <img src="https://resource.jxtszn.com/20230513/92910d006520bf43ed3dd6db78f63f8d.png" alt="">
											<p>拓世智能抖音号</p> -->
											
											<img :src="config.dy_code.value" alt="">
											<p>{{config.dy_code.remark}}</p>
										</div>
										<div class="tip-icon-text">
											<img src="@/assets/img/douyin_sign.png" alt="">
											<p>抖音号</p>
										</div>
									</el-tooltip>
								</div>
							</div>
						</el-col>
					</el-row>
				</div>
				<div class="footer-record f-r-c-c">Copyright © {{config?.record_time?.value||""}}
					{{config?.name?.value||""}} 版权所有 |  <a style="margin-left: 4px" href="https://beian.miit.gov.cn" target="_blank">{{config?.record_number?.value||""}}</a></div>
			</el-col>
			<el-col class="hidden-sm-and-up footer-xs" :xs="24">
				<div class="tip">
					<ul class="img f-r-s-s">
						<li @click="seticon('wx')">
							<img src="@/assets/img/weixin_sign.png" alt="">
						</li>
						<li @click="seticon('sp')">
							<img src="@/assets/img/shipin_sign.png" alt="">
						</li>
						<li @click="seticon('dy')">
							<img src="@/assets/img/douyin_sign.png" alt="">
						</li>
					</ul>
					<!-- 邮箱 -->
					<div class="footer-email">邮箱：{{config?.email?.value||""}}</div>
					<!-- 地址 -->
					<div class="footer-address">集团地址：{{config?.address?.value||""}}</div>
				</div>
					
				<el-collapse v-model="activeNames" accordion>
						<el-collapse-item :title="list.class_name" :name="n" 
							v-for="(list, n) in lists" :key="n+list.class_name">
							<div class="f-r-s-c footer-xs-nav"
								v-for="(nav, m) in list.children" :key="n+nav.class_name+m"
								@click="navTo(nav, m)">{{nav.class_name}}</div>
						</el-collapse-item>
						<el-collapse-item title="联系我们" name="1-1" >
							<div class="f-r-s-c footer-xs-nav">邮箱：{{config?.email?.value}}</div>
							<div class="f-r-s-c footer-xs-nav">电话：{{config?.phone?.value}}</div>
							<div class="f-r-s-c footer-xs-nav">地址：{{config?.address?.value}}</div>
						</el-collapse-item>
						<el-collapse-item title="友情链接" name="1-2" >
							<div class="f-r-s-c footer-xs-nav" v-for="link in links" :key="link.id">{{link.title}}</div>
						</el-collapse-item>
				</el-collapse>
				<div>
					<div class='footer-xs-img'>
						<img :src="config?.logo?.value" alt="">
					</div>
					<div class="f-c-c-c footer-xs-record">
						<span>Copyright © {{config?.record_time?.value||""}}{{config?.name?.value||""}} 版权所有</span> 
						<a href="https://beian.miit.gov.cn" target="_blank">{{config?.record_number?.value||""}}</a>
					</div>
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	import { footerService } from "@/utils/api/index.js"
	export default {
		name: "FooterNav",
		data() {
			return {
				activeNames: '1',
				links: "",
				lists: "",
				config: "",
			}
		},
		created() {
			this.getFooterNav();
		},
		methods: {
			getFooterNav() {
				footerService()
					.then(res => {
						// console.log("页脚数据", res)
						if (res.code == 0) {
							let d = res.data;
							this.links = d.links;
							this.lists = d.lists;
							this.config = d.config;
						}
					})
			},
			seticon(name){
				if(name == 'wx'){
					this.$emit("setShadow", {
						isShadow: true,
						shadowIcon: this.config?.wechat_official?.value,
						shadowIconText: this.config?.wechat_official?.remark,
					})
				}
				if(name == 'sp'){
					this.$emit("setShadow", {
						isShadow: true,
						shadowIcon: this.config?.video_code?.value,
						shadowIconText: this.config?.video_code?.remark,
					})
				}
				if(name == 'dy'){
					this.$emit("setShadow", {
						isShadow: true,
						shadowIcon: this.config?.dy_code?.value,
						shadowIconText: this.config?.dy_code?.remark,
					})
				}
			},
			navTo(item, n){
				// console.log("footernav", item, n)
				let query = item.sign ? {sign: item.sign} : {};
				if(item.page == "/business"){
					query.index = n
				}
				if(item.page == "/about_us"){
					query.refName = item.sign;
					query.sign = "about_us"
				}
				this.$router.push({path: item.page, query});
			},
		}
	}
</script>

<style lang="less">
	.footer-box {
		.el-collapse{
			border-top: 0px solid #15192d;
			border-bottom: 0px solid #15192d;
		}
		.el-collapse-item__wrap,
		.el-collapse-item__header{
			font-size: 14px;
			font-weight: 500;
			color: #FFF;
			background-color: #00041a;
			border-bottom: 0px solid #15192d;
		}
		.el-collapse-item__content{
			padding-bottom: 0px;
			background-color: #00041a;
		}
	}
</style>
<style lang="less">
.el-tooltip__popper.is-light{
	border: 0px;
	padding: 0px !important;
	.fixed_code{
		padding: 20px;
		background: #FFF;
		border-radius: 6px;
		box-shadow: 0 0 10px rgb(0 0 0 / 15%);
		transition: all 0.3s;
		img{
			// height: 100px;
            width: 100%;
            max-width: 120px;
			background: #FFF;
		}
		p{
			padding-top: 20px;
			font-size: 16px;
			font-weight: 400;
			line-height: 22px;
		}
	}
	
	.zx{
		background: rgba(231, 246, 255, 1);
	}
}
</style>
<style lang="less" scoped>
	.footer-box {
		font-size: 12px;
		color: #fff;
		background-color: #00041a;
		.footer {
			max-width: 1180px;
			margin: 0 auto;
			.footer-info {
				padding: 20px 20px 0px;
				border-bottom: 1px solid #15192d;
				.footer-lists {
					text-align: left;
					.footer-list-box {
						padding: 0px 10px;
						.footer-list {
							padding: 5px 0px;
							cursor: pointer;
							font-size: 18px;
							line-height: 24px;
							font-weight: 500;
						}
						.footer-nav {
							padding: 5px 0px;
							cursor: pointer;
							color: #b6b6b6;
							font-size: 14px;
							line-height: 20px;
							font-weight: 400;
						}
					}
				}
				.footer-config {
					cursor: default;
					padding-left: 20px;
					border-left: 1px solid #15192d;
					text-align: right;
					.footer-sub_name {
						padding: 5px 0px;
						// cursor: pointer;
						font-size: 18px;
						line-height: 24px;
						font-weight: 500;
					}
					.footer-address {
						margin-top: 20px;
					}
					.footer-address,
					.footer-totline,
					.footer-email,
					.footer-phone {
						padding: 5px 0px;
						// cursor: pointer;
						color: #b6b6b6;
						font-size: 14px;
						line-height: 20px;
						font-weight: 400;
					}
					.footer-img {
						margin-top: 40px;
						margin-bottom: 10px;

						img {
							height: 84px;
							width: 84px;
						}
					}
				}
				.footer-config1{
					padding: 20px;
					.img{
						padding-top: 10px;
						// width: 80%;
						height: 58px;
						img{
							height: 100%;
						}
					}
					.footer-email{
						padding-top: 20px;
					}
					.footer-address{
						padding-top: 20px;
					}
					.tip{
						width: 100%;
						padding-top: 20px;
						.tip-img{
							margin-right: 30px;
							img{
								height: 58px;
								width: 58px;
							}
						}
						.tip-icon-text{
							p{
								padding-top: 16px;
							}
						}
					}
				}
			}
			.footer-record {
				cursor: pointer;
				height: 78px;
			}
			.footer-xs {
				padding: 20px;
				.footer-xs-nav{
					padding: 5px 20px 5px;
					// cursor: pointer;
					color: #b6b6b6;
					font-size: 12px;
					line-height: 24px;
					font-weight: 400;
				}
				.footer-xs-img{
					margin-top: 40px;
					margin-bottom: 20px;
					text-align: center;
					img{
						height: 36px;
						width: 134px;
					}
				}
				.footer-xs-record{
					padding: 5px 20px 35px;
					color: #b6b6b6;
					font-size: 12px;
					line-height: 24px;
					font-weight: 400;
				}
				.tip{
					padding: 10px 0px 20px;
					li{
						margin-right: 30px;
						img{
							height: 48px;
							width: 48px;
						}
					}
					.footer-email{
						padding-top: 16px;
						text-align: left;
					}
					.footer-address{
						padding-top: 16px;
						text-align: left;
					}
				}
			}
		}
	}
	
</style>
