<template>
	<el-row class="nav">
		<!-- pc端 -->
		<el-col class="nav-box hidden-xs-only">
			<el-row class="nav h100">
				<!-- 图标 -->
				<el-col class="nav-img f-r-s-c h100" :span="6">
					<img :src="logo" alt="">
				</el-col>
				<!-- 导航 -->
				<el-col class="h100" :span="18">
					<ul class="f-r-b-c h100">
						<li class="nav-item" v-for="item in bar" :key="item.id">
							<div :class="[item.jump_type==1&&item.page==''&&'nav-no_page']" @click.stop="navTo(item)">
								<img v-if="item.bar_type==2" style="width: 22px; height: 18px; margin-right: 10px;"
									:src="item.back_pic" alt="">{{item.class_name}}
							</div>
							<ul class="nav-item-uli" v-if="item.children?.length">
								<li v-for="itemChildren in item.children" :key="itemChildren.id"  @click.stop="navTo(itemChildren)">
									{{itemChildren.class_name}}
								</li>
							</ul>
						</li>
					</ul>
				</el-col>
			</el-row>
		</el-col>
		<!-- 手机端 -->
		<el-col class="nav-box-tel hidden-sm-and-up">
			<el-row class="nav h100 relative">
				<el-col class="nav-img f-r-s-c h100" :span="16">
					<img :src="logo_colour" alt="">
				</el-col>
				<el-col class='nav-menu  h100 f-r-c-c' :span="8" @click.native="istelnavshow=!istelnavshow">
					<div class="iconfont icon-nav"></div>
				</el-col>
				<div class="nav-ul absolute" :class="[istelnavshow?'':'nav-ul0']">
					<ul>
						<template v-for="item in bar">
							<li class="nav-li"  v-if="item.page" :key="item.id"  @click.stop="navTo(item)">
								<div class="f-r-b-c">
									<span>{{item.class_name}}</span>
									<span 
										v-if="item.children&&item.children.length" 
										:class="[nowlist == item.page?'el-icon-arrow-up':'el-icon-arrow-down', 'icon']"
										@click.stop="setNowList(item.page)"></span>
								</div>
								<template v-if="item.children&&item.children.length">
									<ul v-show="istelnavshow&&nowlist == item.page">
										<li v-for="itemChildren in item.children" :key="itemChildren.id"  @click.stop="navTo(itemChildren)">
											{{itemChildren.class_name}}
										</li>
									</ul>
								</template>
							</li>
						</template>
					</ul>
				</div>
			</el-row>
		</el-col>
	</el-row>
</template>

<script>
	import { barService } from "@/utils/api/index.js"
	export default {
		name: "NavPage",
		data() {
			return {
				bar: "",
				logo: "",
				logo_colour: "",
				preName: "",
				preParams: "",
				
				istelnavshow: false,		// 手机导航显示
				nowlist: "",				// 当前列表
			}
		},
		created() {
			this.getBarData()
		},
		methods: {
			getBarData() {
				barService({v: "v2"})
					.then(res => {
						// console.log("导航栏", res);
						if (res.code == 0) {
							let d = res.data;
							this.bar = d.bar;
							this.logo = d.logo;
							this.logo_colour = d.logo_colour;
						}
					})
			},
			setNowList(name){
				let nowlist = this.nowlist;
				if(nowlist == name){
					this.nowlist = "";
				}
				else{
					this.nowlist = name;
				}
			},
			navTo(item) {
				this.istelnavshow = false;
				let batType = item.bar_type;
				let jumpType = item.jump_type;
				let name = item.page;
				let params = item.sign;
				// this.$store.commit("setSigns", {name, sign: params});
				if (batType == 2) { 
					this.$router.push({path: name, query: {sign: params}})
					// console.log("拓世云", item); 
				} 
				else if(jumpType == 2 && item.page) { 
					// console.log("网站", item); 
					window.open(item.page)
				} 
				else if(name) {
					this.$router.push({path: name, query: {sign: params}})
					// if(name == this.preName && params == this.preParams){
					// 	this.$router.go(0)
					// }
					// else{ 
					// 	this.preName = name;
					// 	this.preParams = params;
					// 	this.$router.push({path: name, query: {sign: params}})
					// }
				}
			},
		}

	}
</script>

<style scoped lang="less">
	.nav-box {
		position: fixed;
		position: absolute;
		top: 0;
		z-index: 9999;
		width: 100%;
		height: 72px;
		color: #FFF;
		font-size: 15px;
		font-weight: 500;

		.nav {
			max-width: 1180px;
			margin: 0px auto;
			padding: 0px 20px;

			.nav-img {
				img {
					width: 134px;
					height: 36px;
				}
			}

			ul {
				cursor: default;

				.nav-item {
					cursor: pointer;
					position: relative;

					.nav-item-uli {
						display: none;

						&:hover {
							display: block;
							position: absolute;
							transform: translate(-20%, 100%);
							bottom: -10px;
							padding: 16px 20px;
							color: #001121cc;
							font-weight: 400;
							border-radius: 10px;
							background-color: #FFF;

							li {
								cursor: pointer;
								text-align: left;
								font-size: 14px;
								line-height: 28px;
								white-space: nowrap;
							}
						}
					}

					&:hover {
						div {
							&::before {
								content: "";
								position: absolute;
								bottom: -4px;
								left: 50%;
								transform: translate(-50%, 100%);
								display: block;
								width: 28px;
								border-bottom: 2px solid #FFF;
							}

							&::after {
								content: "";
								position: absolute;
								bottom: 1px;
								transform: translate(0%, 100%);
								display: block;
								width: 68px;
								border-bottom: 12px solid transparent;
							}

						}

						.nav-no_page {
							&::before {
								content: "";
								display: none;
							}

							&::after {
								content: "";
								display: none;
							}
						}

						.nav-item-uli {
							display: block;
							position: absolute;
							transform: translate(-10%, 100%);
							bottom: -8px;
							padding: 16px 20px;
							color: #001121cc;
							font-weight: 400;
							border-radius: 10px;
							background-color: #FFF;

							li {
								cursor: pointer;
								text-align: left;
								font-size: 14px;
								line-height: 28px;
								white-space: nowrap;

								&:hover {
									color: #008cd6;
								}
							}
						}
					}
				}
			}
		}
	}

	.nav-box-tel {
		position: relative;
		z-index: 996;
		width: 100%;
		height: 72px;
		background-color: #FFF;

		.nav {
			max-width: 1180px;
			margin: 0px auto;
			.nav-img {
				padding: 0px 15px;
				img {
					width: 108px;
					height: 26px;
				}
			}
			.nav-menu {
				.icon-nav{
					font-size: 24px;
				}
			}
			.nav-ul{
				top: 100%;
				left: 0px;
				height: 360px;
				width: 100%;
				z-index: 998;
				overflow: hidden;
				background-color: #FFF;
				// padding-top: 20px;
				padding-bottom: 20px;
				border-bottom-left-radius: 12px;
				border-bottom-right-radius: 12px;
				box-shadow: 0px 4px 12px 0px rgba(4,17,40,0.08);
				transition: all 0.3s ;
				&.nav-ul0{
					height: 0px;
					padding-bottom: 0px;
				}
				ul{
					max-height: 340px;
					overflow-y: auto;
					width: 100%;
					.nav-li{
						// height: 42px;
						padding-left: 20px;
						padding-right: 20px;
						width: 100%;
						div{
							color: #001121;
							height: 60px;
							line-height: 36px;
							width: 100%;
							border-bottom: 1px solid #00112114;
							.icon{
								display: block;
								width: 60px;
								line-height: 60px;
								font-size: 16px;
								font-weight: 700;
								text-align: center;
							}
						}
						ul{
							background-color: #F7F7F7;
							padding-bottom: 10px;
							padding-left: 20px;
							padding-right: 20px;
							// margin-top: 10px;
							// border-top-left-radius: 12px;
							// border-top-right-radius: 12px;
							border-bottom-left-radius: 12px;
							border-bottom-right-radius: 12px;
							transition: all 0.3s;
							li{
								text-align: left;
								// list-style: disc;
								font-weight: 500;
								height: 60px;
								line-height: 60px;
								border-bottom: 1px solid #00112114;
							}
						}
					}
				}
				
			}
		}
	}
</style>
