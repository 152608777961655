<template>
	<div class="home">
		<!-- 轮播图@touchmove="a" -->
		<div v-if="true" class="loop-img" @touchstart="clientX[0] = $event.targetTouches[0].clientX"
			@touchmove="clientX[1] = $event.targetTouches[0].clientX" @touchend="switchImg">
			<el-carousel ref="carousel" height="auto" width="auto" @change="change">
				<el-carousel-item v-for="item in banner" :key="item">
					<!-- <img :src="item" alt="轮播图背景"> -->
					<el-image ref="image" style="width: 100%" :src="item" fit="fit" class="banner-img" />
				</el-carousel-item>
			</el-carousel>
		</div>
		<!-- 关于拓世 -->
		<div class="home-box" >
			<!-- 标题 -->
			<el-row class="home-item-box home-title">
				<!-- pc端 -->
				<el-col class="hidden-xs-only pc-title">
					<div class='title-text'>{{about_us.class_name}}</div>
					<div class='title-subtext'>{{about_us.class_subhead_name}}</div>
				</el-col>
				<!-- 手机端 -->
				<el-col class="hidden-sm-and-up tel-title">
					<div class='title-text'>{{about_us.class_name}}</div>
					<div class='title-subtext'>{{about_us.class_subhead_name}}</div>
				</el-col>
				<!-- <el-col></el-col> -->
			</el-row>
			<!-- 内容主体部分 -->
			<el-row class="home-item-box aboutUs-box">
				<el-col class="hidden-xs-only pc-aboutUs">
					<el-row class="h100">
						<el-col class="aboutUs-content f-c-e-s" :span="14">
							<div class="aboutUs-desc flex1 f-c-c-s">
								<span>{{about_us.desc}}</span>
								<div class="btn">
									<el-button @click="navTo(about_us)">查看更多 <i class="el-icon-right"></i> </el-button>
								</div>
							</div>
							<!-- <div class="aboutUs-list-box"></div> -->
							<div class="aboutUs-list-box f-r-a-c"
								:style="{ backgroundImage: `url(${about_us.about_bottom_pic})`,
									backgroundSize: 'cover',
									backgroundPosition: 'center top',
									backgroundRepeat: 'no-repeat',}">
								<div>
									<div>
										<span class="number">{{about_us.about_money}}</span>
										<span>{{about_us.about_money_unit}}</span>
									</div>
									<div class="f-r-c-c">注册资本</div>
								</div>
								<div>
									<div>
										<span class="number">{{about_us.about_year}}</span>
										<span>余年</span>
									</div>
									<div class="f-r-c-c">发展经验</div>
								</div>
								<div>
									<div>
										<span class="number">{{about_us.about_patent}}</span>
										<span>项</span>
									</div>
									<div class="f-r-c-c">专利著作</div>
								</div>
								<div>
									<div>
										<span class="number">{{about_us.about_technology}}</span>
										<span>余名</span>
									</div>
									<div class="f-r-c-c">专业技术人才</div>
								</div>
							</div>
						</el-col>
						<el-col class="aboutUs-img" :span="10">
							<img :src="about_us.icon" alt="">
						</el-col>
					</el-row>
				</el-col>
				<el-col class="hidden-sm-and-up tel-aboutUs">
					<div class="aboutUs-desc">{{about_us.desc}}</div>
					<div class="aboutUs-list-box f-r-b-c"
						:style="{ backgroundImage: `url(${about_us.about_bottom_pic})`,
							backgroundSize: 'cover',
							backgroundPosition: 'center top',
							backgroundRepeat: 'no-repeat',}">
						<div>
							<div>
								<span class="number">{{about_us.about_money}}</span>
								<span>{{about_us.about_money_unit}}</span>
							</div>
							<div class="f-r-c-c">注册资本</div>
						</div>
						<div>
							<div>
								<span class="number">{{about_us.about_year}}</span>
								<span>余年</span>
							</div>
							<div class="f-r-c-c">发展经验</div>
						</div>
						<div>
							<div>
								<span class="number">{{about_us.about_patent}}</span>
								<span>项</span>
							</div>
							<div class="f-r-c-c">专利著作</div>
						</div>
						<div>
							<div>
								<span class="number">{{about_us.about_technology}}</span>
								<span>余名</span>
							</div>
							<div class="f-r-c-c">专业技术人才</div>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
		<!-- 解决方案 -->
		<div class="home-box">
			<!-- 背景图片 -->
			<el-row class="pc-bg">
				<el-col class="h100 hidden-xs-only" :sm="24">
					<ul ref="solutionBg" class="relative f-r-s-s no-scroll" style="height: 640px; width: 100%; overflow: auto;">
						<li class="position" v-for="(s, n) in pcSolution" :key="'s1-bg'+ n + s.id" style="min-width: 100%; height: 100%;">
							<img :src="s.back_pic" alt="" style="min-width: 100%; height: 100%;">
						</li>
					</ul>
				</el-col>
			</el-row>
			<!-- 标题 -->
			<el-row class="home-item-box home-title">
				<!-- pc端 -->
				<el-col class="hidden-xs-only pc-title">
					<div class='title-text' style="color: #FFFFFF;">{{solution.class_name}}</div>
					<div class='title-subtext' style="color: #FFFFFF;">{{solution.class_subhead_name}}</div>
				</el-col>
				<!-- 手机端 -->
				<el-col class="hidden-sm-and-up tel-title">
					<div class='title-text'>{{solution.class_name}}</div>
					<div class='title-subtext'>{{solution.class_subhead_name}}</div>
				</el-col>
				<!-- <el-col></el-col> -->
			</el-row>
			<!-- 内容主体部分 -->
			<el-row class="home-item-box solution-box">
				<el-col class="hidden-lg-and-down btn-box f-r-b-c">
					<div class="left-btn h100" @click="solutionHandle(solution_Index-1, pcSolution.length)">
						<img src="../../assets/img/left-arrow.png" alt="">
					</div>
					<div class="right-btn h100" @click="solutionHandle(solution_Index+1, pcSolution.length)">
						<img src="../../assets/img/right-arrow.png" alt="">
					</div>
				</el-col>
				<el-col class="hidden-xs-only pc-solution">
					<ul ref="solutionLi" class="solution-bg f-r-s-s" style="padding: 2px;">
						<!-- :class="{'active': n==solution_Index-1}"
						style="box-sizing: border-box;" -->
						<li v-for="(s, n) in pcSolution" :key="'s-bg'+n+s.id"
							@click="solutionHandle(n, pcSolution.length)">
							<div class="h100" style="width: 100%;" :class="{'active': n==solution_Index}">
								<img :src="s.sub_icon" alt="">
								<div class="article f-c-c-c">
									<div class="title">{{s.class_name}}</div>
									<div class="text">{{s.class_intro}}</div>
									<div @click.stop="navTo(s)" class="btn el-icon-right"></div>
								</div>
							</div>
						</li>
					</ul>
				</el-col>
				<el-col class="hidden-sm-and-up tel-solution">
					<ul class="f-rw-s-s">
						<li v-for="s in solution.detail" :key="s.id">
							<!-- <img :src="s.back_pic" alt=""> -->
							<div class="solution-item f-c-e-s"
								:style="{ backgroundImage: `url(${s.sub_icon})`,
								backgroundSize: 'cover',
								backgroundPosition: 'center top',
								backgroundRepeat: 'no-repeat',}"
								@click="navTo(s)">
								<div class="title">{{s.class_name}}</div>
								<div class="desc">{{s.class_intro}}</div>
							</div>
						</li>
					</ul>
				</el-col>
			</el-row>
		</div>
		<!-- 业务领域 -->
		<div class="home-box business">
			<!-- 背景图片 -->
			<el-row class="pc-bg">
				<el-col class="h100 hidden-xs-only" :sm="24" 
					:style="{ backgroundImage: `url(${partnerBgImg})`,
						backgroundSize: 'cover',
						backgroundPosition: 'center top',
						backgroundRepeat: 'no-repeat',}">
				</el-col>
			</el-row>
			<!-- 标题 -->
			<el-row class="home-item-box home-title">
				<!-- pc端 -->
				<el-col class="hidden-xs-only pc-title">
					<div class='title-text'>{{business.class_name}}</div>
					<div class='title-subtext'>{{business.class_subhead_name}}</div>
					<div class="pc-more" @click="navTo(business)">查看更多 <i class="el-icon-right"></i> </div>
				</el-col>
				<!-- 手机端 -->
				<el-col class="hidden-sm-and-up tel-title">
					<div class='title-text'>{{business.class_name}}</div>
					<div class='title-subtext'>{{business.class_subhead_name}}</div>
				</el-col>
				<!-- <el-col></el-col> -->
			</el-row>
			<!-- 内容主体部分 -->
			<el-row class="home-item-box business-box">
				<el-col class="hidden-xs-only pc-business">
					<div class="business-swiper">
						<ul ref="businessSwiper" class="business-swiper-box f-r-s-s">
							<li v-for="(item, n) in pcBusiness" :key="'business'+n"
								@click="businessHandle(n+1, pcBusiness.length)">
								<div class="f-r-c-c business-box">
									<img :src="item.sub_icon" alt="">
									<div class="business-detail f-c-b-s" :class="{'business-detail-active': n==bus_Index-1}">
										<div class="title">{{item.title}}</div>
										<div class="subtitle">{{item.sub_title}}</div>
										<div class="text">{{item.desc}}</div>
										<div class="btn" @click="navTo(business, n)"><i class="el-icon-right"></i></div>
									</div>
								</div>
							</li>
						</ul>
						<ul class="business-indicator f-r-c-c">
							<!-- 左箭头 -->
							<li class="indicator-item-pre el-icon-back f-r-c-c"
								@click="businessHandle(bus_Index-1, pcBusiness.length)"></li>
							<li class="indicator-item f-r-c-s" 
								v-for="(detail, n) in business.detail" :key="'business-icon'+n"
								@click="businessHandle(n+3, pcBusiness.length)">
								<div :class="{active: n+2==bus_Index-1}"></div>
							</li>
							<!-- 右箭头 -->
							<li class="indicator-item-next el-icon-right f-r-c-c"
								@click="businessHandle(bus_Index+1, pcBusiness.length)"></li>
						</ul>
					</div>
				</el-col>
				<el-col class="hidden-sm-and-up tel-business">
					<el-carousel class="business-list" height="48vw"  arrow="never"
						:autoplay="false" :loop="true">
						<el-carousel-item class="business-item" v-for="(detail, n) in business.detail" :key="'business-tel'+n">
							<div class="h100 business-item-img" 
								:style="{backgroundImage: `url(${detail.sub_icon})`}"
								@click="navTo(business, n)">
								<div class="business-item-text f-c-s-s">
									<div class="title">{{detail.title}}</div>
									<div class="desc">{{detail.desc}}</div>
								</div>
								<!-- <img :src="detail.icon" alt=""> -->
							</div>
						</el-carousel-item>
					</el-carousel>
				</el-col>
			</el-row>
		</div>
		<!-- 新闻资讯 -->
		<div class="home-box news">
			<!-- 标题 -->
			<el-row class="home-item-box home-title">
				<!-- pc端 -->
				<el-col class="hidden-xs-only pc-title">
					<div class='title-text'>{{news.class_name}}</div>
					<div class='title-subtext'>{{news.class_subhead_name}}</div>
					<div class="pc-more" @click="navTo(news)">查看更多 <i class="el-icon-right"></i> </div>
				</el-col>
				<!-- 手机端 -->
				<el-col class="hidden-sm-and-up tel-title">
					<div class='title-text'>{{news.class_name}}</div>
					<div class='title-subtext'>{{news.class_subhead_name}}</div>
				</el-col>
				<!-- <el-col></el-col> -->
			</el-row>
			<!-- 内容主体部分 -->
			<el-row class="home-item-box news-box">
				<!-- pc端 -->
				<el-col class="hidden-sm-and-down pc-news" :lg="24">
					<ul class="f-r-b-s">
						<li class="news-item" v-for="detail in news.detail" :key="detail.id">
							<div class="news-date f-r-s-e">
								<span>{{detail.update_time[0]}}</span> /
								<span>{{detail.update_time[1]}}</span>
								<span class="new-date-year">{{detail.update_time[2]}}</span>
							</div>
							<div class="news-item-box">
								<div class="news-item-bg" :style="{backgroundImage: `url('${detail.icon}')`}">
									<div class="bg-box h100 f-c-e-s">
										<div class="title">{{detail.title}}</div>
									</div>
								</div>
								<div class="news-item-text f-c-b-s">
									<div>
										<div class="title">{{detail.title}}</div>
										<div class="desc" v-text="detail.desc"></div>
									</div>
									<div class="btn"><span>查看详情</span><i class="el-icon-right"></i></div>
								</div>
							</div>
						</li>
					</ul>
				</el-col>
				<!-- 手机端 -->
				<el-col class="hidden-md-and-up tel-news">
					<el-carousel class="news-list" height="64vw"  arrow="never"
						:autoplay="false" :loop="true">
						<el-carousel-item class="news-item" v-for="detail in news.detail" :key="detail.id">
							<div class="h100">
								<div class="news-item-text f-c-e-s">
									<div class="title">{{detail.title}}</div>
									<div class="index">1/3</div>
								</div>
								<img :src="detail.icon" alt="">
							</div>
						</el-carousel-item>
					</el-carousel>
				</el-col>
			</el-row>
		</div>
		<!-- 合作伙伴 -->
		<div class="home-box partner">
			<!-- 背景图片 -->
			<el-row class="pc-bg">
				<el-col v-show="partnerBgImg" class="h100 hidden-xs-only" :sm="24" 
					:style="{ backgroundImage: `url(${partnerBgImg})`,
						backgroundSize: 'cover',
						backgroundPosition: 'center top',
						backgroundRepeat: 'no-repeat',}">
				</el-col>
			</el-row>
			<!-- 标题 -->
			<el-row class="home-item-box home-title">
				<!-- pc端 -->
				<el-col class="hidden-xs-only pc-title">
					<div class='title-text'>{{partner.class_name}}</div>
					<div class='title-subtext'>{{partner.class_subhead_name}}</div>
				</el-col>
				<!-- 手机端 -->
				<el-col class="hidden-sm-and-up tel-title">
					<div class='title-text'>{{partner.class_name}}</div>
					<div class='title-subtext'>{{partner.class_subhead_name}}</div>
				</el-col>
				<!-- <el-col></el-col> -->
			</el-row>
			<!-- 内容主体部分 -->
			<el-row class="home-item-box partner-box">
				<!-- pc端 -->
				<el-col class="hidden-xs-only pc-partner">
					<ul class="f-rw-s-c">
						<li class="partner-item-li" v-for="detail in partner.detail" :key="detail.id">
							<img :src="detail.icon" alt="">
						</li>
					</ul>
				</el-col>
				<!-- 手机端 -->
				<el-col class="hidden-sm-and-up tel-partner">
					<el-carousel height="auto" indicator-position="none" arrow="never" :autoplay="true" :loop="true">
						<el-carousel-item v-for="(arr, n) in partnerArr" :key="n">
							<ul class="f-rw-b-c">
								<li class="partner-item-li" v-for="detail in arr" :key="detail.id">
									<img :src="detail.icon" alt="">
								</li>
							</ul>
						</el-carousel-item>
					</el-carousel>
				</el-col>
			</el-row>
		</div>
		<!-- 加入我们 -->
		<div class="home-box join_us">
			<img :src="join_us.back_pic" alt="">
			<div class=" join_us-text">
				<div>{{join_us.class_subhead_name}}</div>
				<div class="btn">
					<el-button type="primary" @click="navTo(join_us)">加入我们 <i class="el-icon-right"></i> </el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { homeService } from "@/utils/api/index.js"
	export default {
		name: 'HomePage',
		components: {},
		data() {
			return {
				clientX: [], 				// 手机端滑动时开始与结束的距离
				n: "", 						// 当前轮播
				banner: "", 				// 轮播图
				about_us: "", 				// 关于我们
				solution: "", 				// 解决方案
				pcSolution: "",				// 解决方案详情(pc端处理)
				solution_Index: 4,			// 解决方案轮播图当前位置
				business: "", 				// 业务领域
				pcBusiness: "",				// 业务领域详情(pc端处理)
				bus_Index: 3,				// 业务领域轮播图当前位置
				news: "", 					// 新闻
				partner: "", 				// 合作伙伴
				partnerBg: "client-bg.png", // 服务客户背景图
				join_us: "", 				// 加入我们
				
			}
		},
		computed: {
			partnerBgImg: function() {
				let partnerBg = this.partnerBg;
				return require(`@/assets/img/${partnerBg}`);
			},
			partnerArr: function() {
				let partnerArr = [];
				let detail = this.partner?.detail || [];
				let arr = [];
				detail.forEach((d, n) => {
					if (n > 0 && n % 6 == 0) {
						partnerArr.push(arr);
						arr = [];
						arr.push(d);
					} else {
						arr.push(d);
					}
				})
				if (arr.length) {
					partnerArr.push(arr)
				}
				return partnerArr;
			}
		},
		mounted(){
			this.getHomeData();
			
		},
		
		methods: {
			// 数据获取
			getHomeData() {
				homeService()
					.then(res => {
						// console.log(888, res)
						if (res.code == 0) {
							this.toTop();
							let d = res.data;
							d.company_news.detail = d.company_news.detail.map(n => {
								let date = new Date(n.update_time * 1000);
								let y = date.getFullYear()
								let m = date.getMonth() + 1
								m = m > 9 ? m : ('0' + m);
								let d = date.getDay() + 1;
								d = d > 9 ? d : ('0' + d);
								return {
									...n,
									"update_time": [m, d, y]
								}
							})
							let business =  [...d.business.detail]||[];
							let businessLen = business?.length||-1;
							
							if(businessLen == 1){
								business.push(...business, ...business, ...business, ...business, ...business);
							}
							else if(businessLen == 2){
								business.push(...business, ...business);
							}
							else if(businessLen == -1){
								business = [];
							}
							else if(businessLen >= 3){
								business.push(business[0], business[1]);
								business.unshift(business[businessLen-2], business[businessLen-1]);
							}
							let solution = [...d.solution.detail]||[];
							let solutionLen = solution?.length||-1;
							
							if(solutionLen>3){
								solution.push(solution[0], solution[1%solutionLen], solution[2%solutionLen], solution[3%solutionLen]);
								solution.unshift(solution[solutionLen-1], solution[solutionLen-2], solution[solutionLen-3], solution[solutionLen-4]);
							}
							
							// console.log( solution)
							// d.business.detail = business;
							this.banner = d.banner;
							this.about_us = d.about_us;
							this.solution = d.solution;
							this.pcSolution = solution;
							this.business = d.business;
							this.pcBusiness = business;
							this.news = d.company_news;
							this.partner = d.partner;
							this.join_us = d.join_us;
							this.$store.commit("setIsGrey", d.is_grey);
							if (d.is_grey == "1") {
								let html = document.getElementsByTagName("html")[0];
								html.className = "Mourning";
							}
						}
					})
			},
			// 顶部轮播图滑动切换
			switchImg() {
				let x = this.clientX;
				let len = x[0] - x[1];
				if (len > 80) {
					this.$refs.carousel.next();
				} else if (len < -60) {
					this.$refs.carousel.prev();
				}
			},
			// 顶部轮播图切换
			change(n) {
				this.n = n;
			},
			solutionHandle(n, m){
				let testn = this.solution_Index;
				if(testn == n){
					return ;
				}
				else if(n < 4){
					testn = m-(4-(testn-4)); // 当前位置
					n = m-4 -(4-n);
				}
				else if(n >= m - 4){
					n = 4 - (m - n) + 4;
					testn = 4 - (m -testn)+4;
				}
				let x = 0-testn*100;
				this.$refs.solutionBg.childNodes.forEach(li=>{
					li.style.transform = `translateX(${x}%)`
				})
				this.$refs.solutionLi.childNodes.forEach(li=>{
					li.style.transform = `translateX(${x}%)`
				})
				
				let len = testn - n;
				let num = 0;
				let timer = setInterval(()=>{
					num++;
					x = x + len*4
					this.$refs.solutionBg.childNodes.forEach(li=>{
						li.style.transform = `translateX(${x}%)`
					})
					this.$refs.solutionLi.childNodes.forEach(li=>{
						li.style.transform = `translateX(${x}%)`
					})
					if(num >= 25){
						clearInterval(timer)
						this.solution_Index = n;
					}
				}, 14)
				return ;
			},
			businessHandle(n, m){
				// console.log(n)
				let testn = this.bus_Index;
				if(testn == n){
					return ;
				}
				else if(n == 2){
					n = m - 2
					testn = m - 1;
				}
				if(n == m -1){
					n = 3;
					testn = 2;
				}
				let x = 150 - testn*100;
				this.$refs.businessSwiper.childNodes.forEach(li=>{
					li.style.transform = `translateX(${x}%)`
				})
				let len = testn - n;
				let num = 0;
				let timer = setInterval(()=>{
					num++;
					x = x + len*4
					this.$refs.businessSwiper.childNodes.forEach(li=>{
						li.style.transform = `translateX(${x}%)`
					})
					if(num >= 25){
						clearInterval(timer)
						this.bus_Index = n;
					}
				}, 14)
				return ;
			},
			navTo(item, n){
				// console.log("utem", item, n)
				let query = item.sign ? {sign: item.sign} : {};
				if(item.page == "/about_us"){
					query.sign = "about_us";
				}
				if(item.page == "/business"){
					query.sign = "business";
					query.index = n;
				}
				if(item.page == "/join_us"){
					query.sign = "join_us";
				}
				this.$router.push({path: item.page, query});
				// this.$router.push({path: item.page, query: {sign: item.page}});
			},
		}
	}
</script>

<style>
	/* .loop-img .el-carousel__arrow{
		display: none;
	} */
	.business .business-box .el-carousel__indicators{
		width: 80%;
		text-align: center;
	}
	.news .tel-news .el-carousel__indicators{
		right: 0px !important;
		left: auto !important;
		bottom: 10px !important;
	}
	/* .loop-img .el-carousel__button, */
	.business .tel-business .el-carousel__button,
	.news .tel-news .el-carousel__button{
		height: 3px !important;
		width: 16px !important;

	}
</style>
<style scoped lang="less">
	// 背景图片
	.pc-bg{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		height: 100%;
		z-index: 0;
	}
	.home {
		width: 100%;
		.home-box{
			position: relative;
			width: 100%;
			.home-item-box{
				max-width: 1170px;
				margin: 0px auto;
				padding: 20px 20px 0px;
			}
		}
		.loop-img {
			img {
				position: relative;
				width: 100%;
				height: 100%;
			}
		}
	}
	
	// 标题
	.home-title{
		// 手机端标题
		.tel-title{
			position: relative;
			padding-left: 16px;
			text-align: left;
			&::before {
				content: "";
				display: block;
				position: absolute;
				top: 50%;
				left: 0px;
				transform: translate(0%, -50%);
				height: 86%;
				width: 3px;
				border-radius: 3px;
				background-color: #008cd6;
			}
			.title-text {
				padding-top: 0px;
				font-size: 22px;
				font-weight: 600;
				line-height: 22px;
			}
			.title-subtext {
				margin-top: 6px;
				// color: #00112180;
				color: #7F8890;
				font-size: 12px;
				font-weight: 400;
				line-height: 12px;
			}
		}
		// pc 端标题
		.pc-title{
			margin-top: 60px;
			position: relative;
			padding-left: 16px;
			text-align: left;
			&::before {
				content: "";
				display: block;
				position: absolute;
				top: 50%;
				left: 0px;
				transform: translate(0%, -50%);
				height: 86%;
				width: 3px;
				background-color: #008cd6;
			}
			.title-text {
				padding-top: 10px;
				font-size: 32px;
				font-weight: 600;
				line-height: 32px;
			}
			.title-subtext {
				margin-top: 6px;
				color: #00112180;
				font-size: 14px;
				font-weight: 400;
				line-height: 14px;
			}
		}
		.pc-more{
			cursor: pointer;
			position: absolute;
			right: 0px;
			top: 50%;
			color: #00112199;
			transform: translateY(-50%);
		}
	}
	// 关于我们
	.aboutUs-box{
		.pc-aboutUs{
			max-height: 648px;
			height: 66vw;
			padding-bottom: 60px;
			.aboutUs-content{
				height: 100%;
				.aboutUs-desc{
					text-align: left;
					padding-right: 8%;
					font-size: 14px;
					font-weight: 400;
					color: #001121cc;
					line-height: 20px;
					span{
						margin-bottom: 60px;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 5;
						-webkit-box-orient: vertical;
					}
				}
				.aboutUs-list-box{
					width: 100%;
					height: 20%;
					background-size: 100% 100%;
					font-size: 12px;
					font-weight: 500;
					line-height: 20px;
					div{
						color: rgba(255, 255, 255, 0.7);
					}
					.number{
						margin-right: 4px;
						font-size: 24px;
						color: #FFFFFF;
						font-weight: 900;
					}
				}
			}
			.aboutUs-img{
				height: 100%;
				img{
					width: 100%;
					height: 100%;
				}
			}
			
		}
		.tel-aboutUs{
			padding-bottom: 96px;
			.aboutUs-desc{
				text-align: left;
				font-size: 14px;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 4;
				-webkit-box-orient: vertical;
			}
			.aboutUs-list-box{
				padding: 0px 30px;
				position: absolute;
				left: 0px;
				bottom: 0px;
				margin-top: 16px;
				height: 78px;
				width: 100%;
				background-size: 100% 100%;
				// background-color: red;
				font-size: 12px;
				font-weight: 500;
				line-height: 20px;
				div{
					color: rgba(255, 255, 255, 0.7);
				}
				.number{
					margin-right: 4px;
					font-size: 24px;
					color: #FFFFFF;
					font-weight: 900;
				}
			}
		}
	}
	// 解决方案
	.solution-box{
		position: relative;
		max-width: 100% !important;
		.btn-box{
			position: absolute;
			left: 50%;
			top: 42%;
			transform: translateY(-50%) translateX(-50%);
			width: 100vw;
			max-width: 1360px !important;
			height: 148px;
			margin: 0px auto;
			.right-btn,
			.left-btn{
				height: 60px;
				width: 60px;
				img{
					width: 100%;
					height: 100%;
				}
			}
		}
		.pc-solution{
			position: relative;
			left: 50%;
			transform: translateX(-50%);
			max-width: 1170px;
			height: 840px;
			.solution-bg{
				position: absolute;
				height: 790px;
				width: 100%;
				overflow: auto;
				&::-webkit-scrollbar {
					display: none;
				}
				li{
					position: relative;
					top: 28.6%;
					height: 64%;
					width: 100%;
					min-width: 28%;
					max-width: 330px;
					padding-right: 20px;
					transform: translateX(-400%);
					.active{
						border: 2px solid #008cd6;
					}
					.article{
						height: 50%;
						width: 100%;
						padding: 16px;
						box-shadow: 0 4px 6px 0 rgb(0 17 33 / 7%), 
								0 -3px 5px 0 rgb(0 17 33 / 4%);
						.title{
							width: 100%;
							text-align: center;
							font-size: 24px;
							font-weight: 500;
							padding-bottom: 20px;
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
						}
						.text{
							width: 100%;
							// width: 230px;
							font-size: 13px;
							font-weight: 400;
							line-height: 24px;
							opacity: .8;
							margin-bottom: 15px;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 2;
							-webkit-box-orient: vertical;
						}
						.btn{
							cursor: pointer;
							text-align: left; 
							width: 100%; 
							padding-left: 20px;
							font-weight: 700;
							&:hover{
								color: #008cd6;
							}
						}
					}
					img{
						height: 50%;
						width: 100%;
					}
				}
			}
		}
		.tel-solution{
			ul{
				li{
					width: 50%;
					height: 62vw;
					padding: 10px 5px 0px;
					// border-radius: 10px;
					.solution-item{
						width: 100%;
						height: 100%;
						padding: 10px;
						border-radius: 10px;
						background-size: 200% 100%;
						color: #FFFFFF;
						.title{
							font-size: 14px;
							font-weight: 700;
							line-height: 32px;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 1;
							-webkit-box-orient: vertical;
						}
						.desc{
							text-align: left;
							font-size: 12px;
							font-weight: 400;
							line-height: 16px;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 2;
							-webkit-box-orient: vertical;
						}
					}
					img{
						height: 100%;
					}
				}
			}
		}
	}
	// 业务领域
	.business {
		.business-box {
			.pc-business{
				.business-swiper{
					padding-top: 20px;
					.business-swiper-box{
						width: 100%;
						max-height: 380px;
						height: 42vw;
						position: relative;
						overflow-x: scroll;
						&::-webkit-scrollbar {
							display: none;
						}
						li{
							padding: 0px 10px;
							transform: translateX(-150%);
							flex-shrink: 0;
							flex-grow: 0;
							height: 100%;
							width: 50%;
							.business-box{
								position: relative;
								height: 100%;
								img{
									height: 100%;
									width: 100%;
								}
								.business-detail{
									cursor: default;
									padding: 20px;
									position: absolute;
									left: 0px;
									top: 10%;
									border-left: 3px solid #008cd6;
									transform: translateY(-50%);
									height: 46%;
									width: 48%;
									min-width: 256px;
									background-color: #FFFFFF;
									transition: all 0.5s;
									opacity: 0;
									.title{
										font-size: 24px;
										font-weight: 400;
										color: #008CD6;
									}
									.subtitle{
										font-size: 14px;
										font-weight: 400;
										color: #00000080;
									}
									.text{
										font-size: 14px;
										font-weight: 400;
										color: #001121cc;
										line-height: 24px;
										overflow: hidden;
										text-overflow: ellipsis;
										display: -webkit-box;
										-webkit-line-clamp: 2;
										-webkit-box-orient: vertical;
									}
									.btn{
										cursor: pointer;
										font-size: 20px;
										font-weight: 700;
										&:hover{
											color: #008cd6;
										}
									}
								}
								.business-detail-active{
									top: 50%;
									opacity: 1;
								}
							}
							
						}
					}
					.business-indicator{
						width: 100%;
						height: 160px;
						margin-bottom: 40px;
						.indicator-item{
							cursor: pointer;
							margin: 0px 5px;
							padding: 16px 0px;
							border-radius: 0px;
							border: 0px;
							div{
								background-color: #0011211a;
								height: 2px;
								width: 30px;
								border-radius: 2px;
							}
							.active{
								color: #FFF;
								background-color: #008CD6;
							}
							&:hover{
								div{
									color: #FFF;
									background-color: #008CD6;
								}
							}
						}
						.indicator-item-pre{
							cursor: pointer;
							width: 42px;
							height: 42px;
							border-radius: 50%;
							border: 1px solid #CCC;
							margin-right: 20px;
							&:hover{
								color: #FFF;
								background-color: #008CD6;
							}
						}
						.indicator-item-next{
							cursor: pointer;
							width: 42px;
							height: 42px;
							border-radius: 50%;
							border: 1px solid #CCC;
							margin-left: 20px;
							&:hover{
								color: #FFF;
								background-color: #008CD6;
							}
						}
						li{
						}
					}
				}
			}
			.tel-business{
				padding-bottom: 10px;
				.business-list {
					.business-item{
						height: 96%;
						height: 100%;
						border-radius: 10px;
						.business-item-img{
							background-repeat:  no-repeat;
							background-position: top center;
							background-size: cover;
						}
						.business-item-text {
							position: absolute;
							z-index: 1;
							width: 100%;
							height: 100%;
							color: #FFF;
							padding: 20px;
							font-size: 12px;
							font-weight: 500;
							line-height: 22px;
							text-align: left;
							box-sizing: border-box;
							background-color: #00112166;
							.title{
								color: #FFFFFF;
								font-size: 18px;
								font-weight: 500;
								line-height: 32px;
							}
							.desc{
								// width: 90%;
								overflow: hidden;
								text-overflow: ellipsis;
								display: -webkit-box;
								-webkit-line-clamp: 2;
								-webkit-box-orient: vertical;
							}
						}
						img {
							width: 100%;
							height: 100%;
						}
					}
				} 
			}
		}
	}
	// 新闻中心
	.news{ .news-box{
		.pc-news{
			padding-bottom: 60px !important;
			.news-item{
				max-width: 360px;
				max-height: 468px;
				height: 36vw;
				width: 32%;
				margin: 10px;
				.news-date {
					cursor: default;
					padding: 0px 20px 20px;
					font-size: 24px;
					font-weight: 700;
					line-height: 18px;
					color: #001121;
					.new-date-year {
						margin-left: 4px;
						font-size: 14px;
						line-height: 14px;
						font-weight: 500;
						color: #00112180;
					}
				}						
				.news-item-box{
					cursor: pointer;
					position: relative;
					height: 100%;
					width: 100%;
					.news-item-bg {
						border-radius: 2px;
						height: 100%;
						width: 100%;
						background-size: 200% 100%;
						background-repeat: no-repeat;
						background-position: center;
						transition: all 0.3s;
						.bg-box {
							border-radius: 2px;
							height: 100%;
							width: 100%;
							padding: 12px 20px;
							background-color: #00112136;
						}
						.title {
							color: #FEFEFE;
							font-size: 14px;
							font-weight: 500;
							line-height: 18px;
							text-align: left;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 2;
							-webkit-box-orient: vertical;
						}
					}
					.news-item-text {
						display: none;
						padding-top: 10px;
						width: 100%;
						text-align: left;
						background: #008cd6;
						padding: 20px;
						.title {
							font-size: 16px;
							font-weight: 700;
							line-height: 20px;
							color: #001121;
							color: #fff;
							margin-bottom: 10px;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 2;
							-webkit-box-orient: vertical;
						}
						.desc {
							font-size: 14px;
							font-weight: 400;
							color: #001121b3;
							margin-bottom: 10px;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 4;
							-webkit-box-orient: vertical;
						}
						.btn {
							padding-bottom: 16px;
							color: #fff;
							i {
								color: #fff;
								font-weight: 500;
								margin-left: 6px;
							}
							// &:hover {
							// 	color: #008cd6;
							// }
						}
					}
					&:hover {
						.news-item-bg {
							height: 50%;
							background-size: 100% 100%;
							.bg-box {
								display: none;
							}
						}
						.news-item-text {
							display: flex;
							height: 50%;
						}
					}
				}
			}
		}
		.tel-news{ 
			padding-bottom: 10px;
			.news-list {
				.news-item{
					height: 96%;
					height: 100%;
					border-radius: 10px;
					.news-item-text {
						position: absolute;
						z-index: 1;
						width: 100%;
						height: 100%;
						color: #FFF;
						padding: 20px;
						font-size: 14px;
						font-weight: 500;
						line-height: 22px;
						text-align: left;
						box-sizing: border-box;
						background-color: #00112136;
					}
					img {
						width: 100%;
						height: 100%;
					}
				}
			} 
		}	
	} }
	// 合作伙伴
	.partner {
		.partner-box{
			padding-bottom: 30px !important;
			.pc-partner{
				padding-bottom: 30px !important;
				.partner-item-li {
					margin: 32px 1% 0px;
					padding: 15px 15px;
					width: 18%;
					background-color: #FFF;
					img {
						width: 100%;
						height: auto;
					}
				}
			}
			.tel-partner {
				.partner-item-li {
					border: 1px solid #F3F3F5;
					margin: 32px 1% 0px;
					margin-top: 6px;
					padding: 10px;
					width: 31%;
					background-color: #FFF;
					img {
						width: 100%;
						height: auto;
					}
				}
			}
		}
	}
	// 加入我们
	.join_us {
		// margin-top: 30px;
		img {
			width: 100%;
			height: auto;
			min-height: 160px;
		}
		.join_us-text {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 100%;
			font-size: 4vw;
			font-weight: 700;
			color: #FFF;
			text-align: center;
			.btn{
				margin-top: 10px;
			}
		}
	}
	
	// element ui 样式修改 el-carousel__indicators--horizontal
	.loop-img .el-carousel__item:nth-of-type(1),
	.partner .el-carousel__item:nth-of-type(1) {
		position: relative !important;
	}
</style>
