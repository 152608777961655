import { get, post, put, del } from './request.js'
// import request from './request.js'
/** 导航栏 */
export const barService = (params) => get('/index/bar', params);
/** 页脚接口 */
export const footerService = (params) => get('/index/footer', params);
/** 首页接口 */
export const homeService = (params) => get('/', params);
/** 页面接口 */
export const contentService = (params) => get('/index/content', params);
/** 子公司列表 */
export const companyService = (params) => get('/company/index', params);
/** 添加留言 **/ 
export const commentService = (params) => post('/us/comment', params);
/** 加入我们-招聘 **/ 
export const joinService = (params) => get('/join/index', params);
/** 详情 */
export const detailService = (params) => get('/index/detail', params);
/** 公众号 */
// export const h5Service = (params) => get('/share/h5', params);
export const h5Service = (params) => get('/share/h5?url=' + params.url, {});
/** 公众号接口 */
export const userService = (params) => post('/share/user', params);
/** 公众号接口 */
export const gzhService = (params) => post('/share/gzh', params);
/** 公众号接口 */
export const gzhSignService = (params) => post('/share/sign', params);


/** 页面接口1 */
export const signService = (params) => get('/index/sign', params);


// /** 地图接口 */
// export const mapService = (params) => get('/tszn/map', params);



/** 测试 */
export const testService1 = (params) => get('', params)
export const testService2 = (params) => post('', params)
export const testService3 = (params) => put('', params)
export const testService4 = (params) => del('', params)


