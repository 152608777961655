import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/page/home/index.vue'
import topSight_cloud from '@/page/topSight_cloud/index.vue'
import STORE from "@/store"

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;

//push方法封装
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
    return originalPush.call(this, location).catch(err => err);
};
//replace方法封装
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
    if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
    return originalReplace.call(this, location).catch(err => err);
};

export const routes = [{
		path: '/',
		component: HomeView
	},
	{
		path: '/home',
		name: 'home',
		component: HomeView
	},
	{
		path: '/gzh',
		name: 'gzh',
		component: () => import('@/page/gzh/index.vue')
	},
	{
		path: '/sign',
		name: 'sign',
		component: () => import('@/page/gzh/sign.vue')
	},
	{
		path: '/about_us',
		name: 'about_us',
		component: () => import('@/page/about/index.vue')
	},
	{
		path: '/ai_human',
		name: 'ai_human',
		component: () => import('@/page/ai/index.vue')
	},
	{
		path: '/solution',
		name: 'solution',
		redirect: "/solution/list",
		component: () => import('@/page/project/index.vue'),
		children: [
			{
				path: 'detail',
				name: 'ProDetail',
				component: () => import('@/page/project/detail.vue')
			},
			{
				path: 'list',
				name: 'ProList',
				component: () => import('@/page/project/list.vue')
			},
		]
	},
	{
		path: '/business',
		name: 'business',
		redirect: "/business/list",
		component: () => import('@/page/product/index.vue'),
		children: [
			{
				path: 'detail',
				name: 'ProdDetail',
				component: () => import('@/page/product/detail.vue')
			},
			{
				path: 'list',
				name: 'ProdList',
				component: () => import('@/page/product/list.vue')
			},
		]
	},
	{
		path: '/company_news',
		name: 'company_news',
		component: () => import('@/page/news/index.vue')
	},
	{
		path: '/article_news',
		name: 'article_news',
		component: () => import('@/page/news/detail.vue')
	},
	{
		path: '/contact_us',
		name: 'contact_us',
		component: () => import('@/page/contact/index.vue')
	},
	{
		path: '/join_us',
		name: 'join_us',
		component: () => import('@/page/join/index.vue')
	},
	{
		path: '/topSight_cloud',
		name: 'topSight_cloud',
		component: topSight_cloud,
		meta: { show: true }
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})


router.beforeEach( async (to, from, next) => {
	// console.log(77, to)
	STORE.state.nowRouterQuery = to.query || {};		// 当前路由参数
	STORE.state.nowRouter = to.path;					// 当前路由(全)
	if(to.matched&&to.matched[0]){
		STORE.state.nowFatherRouter = to.matched[0].path; 		// 当前路由(主路由)
	}
	// // console.log(to)
	// // console.log( store.state.nowFatherRouter)

	next()
})


export default router
