<template>
    <div>
        <div class="backimg">
            <img src="./images/img.png" alt="">
        </div>
        <div class="box">
            <div class="top"></div>
            <div class="right"></div>
            <div class="bottom"></div>
            <div class="left"></div>
            <div class="content">
                <div class="title">
                    <p>拓世云平台</p>
                </div>
                <div class="name-input">
                    <img src="./images/name.png" alt="">
                    <input type="text" placeholder="请输入账号" v-model="name" @input="loginName">
                </div>
                <div class="password-input">
                    <img src="./images/password.png" alt="">
                    <input type="password" placeholder="请输入密码" v-model="password" @input="loginPassword">
                </div>
                <div class="code">
                    <div class="code-input">
                        <img src="./images/code.png" alt="">
                        <input type="text" placeholder="请输入验证码" v-model="code" @input="loginCode">
                    </div>
                    <div class="code-img" @click="changeCode">
                        <vertify-code :identifyCode="codeNum" :contentHeight="52" :contentWidth="115" class="code-content" title="看不清楚？点击切换一张"></vertify-code>
                    </div>
                </div>
                <div class="remember">
                     <el-checkbox v-model="checked">记住密码</el-checkbox>
                </div>
                <button class="login" v-if="!flag">登 录</button>
                <button class="Login" @click="submit()" v-if="flag">登 录</button>
            </div>
        </div>
    </div>
</template>

<script>
import VertifyCode from '../../components/Code/index'
export default {
    name: "topSightCloudPage",
    data() {
        return {
            name: '',
            password: '',
            code: '',
            flag: false,
            codeNum: ''
        }
    },
    components: {
        VertifyCode
    },
    created () {
        this.changeCode()
    },
    methods: {
        loginName(e){
            this.name = e.target.value
            if (this.name != '' && this.password != '' && this.code != ''){
                this.flag = true
            } else if (this.name == '' && this.password == '' && this.code == '') {
                this.flag = false
            }
        },
        loginPassword(e){
            this.password = e.target.value
            if (this.name != '' && this.password != '' && this.code != ''){
                this.flag = true
            } else if (this.name == '' && this.password == '' && this.code == '') {
                this.flag = false
            }
        },
        loginCode(e){
            this.code = e.target.value
            if (this.name != '' && this.password != '' && this.code != ''){
                this.flag = true
            } else if (this.name == '' || this.password == '' || this.code == '') {
                this.flag = false
            }
        },
        submit(){
            let name = this.name
            let password = this.password
            let code = this.code
            if (name == '') {
                this.$alert('请输入账号')
            } else if (password == '') {
                this.$alert('请输入密码')
            } else if (code == '') {
                this.$alert('请输入验证码')
            } else if (code != this.codeNum) {
                this.$alert('验证码输入错误')
            } else if (name != '') {
                this.$alert('账号不存在')
                this.createCode()
            }
        },
        createCode () { 
            let codeNum = "";
            let codeLength = 4;//验证码的长度
            let random = new Array(0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 'a',    'b', 'c', 'd', 'e', 'f', 'g',
            'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z');// 随机数
            for (let i = 0; i < codeLength; i++) {//循环操作
            let index = Math.floor(Math.random() * 36);//取得随机数的				索引（0~35）
            codeNum += random[index];//根据索引取得随机数加到code上
            }
            this.codeNum = codeNum
        },
        changeCode () {
          this.createCode()
        },
    }
}
</script>

<style scoped>
*{
    padding: 0;
    margin: 0;
    line-height: 1;
}
.backimg{
    width: 100%;
    position: relative;
    height: 1080px;
}
.backimg img{
    width: 100%;
    height: 100%;
}
.box{
    position: absolute;
    top: 283px;
    left: 1200px;
    width: 484px;
    height: 550px;
    background-color: #052559;
    z-index: 5;
    padding: 62px 0 0 62px;
}
.top{
    position: absolute;
    top: 0;
    left: 0;
    width: 26px;
    height: 26px;
    border-top: 2px solid #30B7FF;
    border-left: 2px solid #30B7FF;
}
.right{
    position: absolute;
    top: 0;
    right: 0;
    width: 26px;
    height: 26px;
    border-top: 2px solid #30B7FF;
    border-right: 2px solid #30B7FF;
}
.bottom{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 26px;
    height: 26px;
    border-bottom: 2px solid #30B7FF;
    border-right: 2px solid #30B7FF;
}
.left{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 26px;
    height: 26px;
    border-bottom: 2px solid #30B7FF;
    border-left: 2px solid #30B7FF;
}
.title p{
    font-size: 24px;
    
    font-weight: 400;
    color: #FFFFFF;
    padding-bottom: 34px;
}
.name-input{
    display: flex;
    align-items: center;
    width: 360px;
    height: 56px;
    border: 2px solid #375078;
    border-radius: 4px;
    margin-bottom: 32px;
    color: white;
    font-size: 18px;
    
    font-weight: 400;
}
.name-input img{
    margin: 0 12px 0 18px;
    width: 21px;
    height: 23px;
}
.name-input input{
    width: 80%;
    height: 23px;
    border: none;
    background-color: transparent;
}
.name-input input::placeholder{
    font-size: 18px;
    
    font-weight: 400;
    color: #B1C2D4;
}
.password-input{
    display: flex;
    align-items: center;
    width: 360px;
    height: 56px;
    border: 2px solid #375078;
    border-radius: 4px;
    margin-bottom: 32px;
    color: white;
    font-size: 18px;
    
    font-weight: 400;
}
.password-input img{
    margin: 0 12px 0 18px;
    width: 21px;
    height: 23px;
}
.password-input input{
    width: 80%;
    height: 23px;
    border: none;
    background-color: transparent;
}
.password-input input::placeholder{
    font-size: 18px;
    
    font-weight: 400;
    color: #B1C2D4;
}
.code{
    display: flex;
}
.code-input{
    display: flex;
    align-items: center;
    width: 224px;
    height: 56px;
    border: 2px solid #375078;
    border-radius: 4px;
    margin-bottom: 26px;
    margin-right: 16px;
    color: white;
    font-size: 18px;
    
    font-weight: 400;
}
.code-input img{
    margin: 0 12px 0 18px;
    width: 21px;
    height: 23px;
}
.code-input input{
    height: 23px;
    width: 60%;
    border: none;
    background-color: transparent;
}
.code-input input::placeholder{
    font-size: 18px;
    
    font-weight: 400;
    color: #B1C2D4;
}
.code-img{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 56px;
    border: 2px solid #375078;
    border-radius: 4px;
}
.remember{
    padding-bottom: 41px;
}
.login{
    width: 360px;
    height: 56px;
    background-color: #025795;
    border-radius: 4px;
    font-size: 24px;
    
    font-weight: 500;
    color: #CFD9E6;
}
.Login{
    width: 360px;
    height: 56px;
    background-color: #008CD6;
    border-radius: 4px;
    font-size: 24px;
    
    font-weight: 500;
    color: white;
}
</style>