<template>
	<div id="app">
		<template v-if="isPoster || isGzh || isSign">
			<router-view />
		</template>
		<template v-else>
			<nav-page></nav-page>
			<router-view />
			<footer-nav @setShadow="setShadow"></footer-nav>

			<div class="shade f-r-c-c" v-if="isShadow" @click="isShadow = false">
				<div class="shade-box f-c-c-c">
					<img :src="shadowIcon" alt="">
					<div v-if="shadowIconText" style="padding-bottom: 10px;">{{shadowIconText}}</div>
					<div class="close"><i class="el-icon-circle-close"></i></div>
				</div>
			</div>
		</template>

		
	</div>
</template>
<script>
	import {
		mapState
	} from "vuex";
	import NavPage from "@/components/nav.vue";
	import footerNav from "@/components/footerNav.vue";
	export default {
		name: "App",
		components: {
			NavPage, 
			footerNav
		},
		data(){
			return {
				isShadow: false,	// 弹窗信息		
				shadowIcon: "",		// 弹窗信息		
				shadowIconText: "",	// 弹窗信息	
				
				isPoster: false,	// 公众号海报
				isGzh: false,
			}
		},
		computed: {
			...mapState({ isGrey: "isGrey", nowRouter: "nowRouter"}),
		},
		watch: {
			// 辨别当前路由是否为 地图页面 & 辨别是否存在重大事件
			nowRouter: {
				handler(v){
					this.isPoster = v === "/poster";
					this.isGzh = v === "/gzh";
					this.isSign = v === "/sign";
					this.isMap = v === "/map";
					let html = document.getElementsByTagName("html")[0];
					if (this.isGrey == "1" && (v == "home" || v == "/")) {
						html.className = "Mourning";
					} else {
						html.className = "";
					}
				},
				immediate: true

			},
		},
		methods: {
			setShadow(item){
				this.isShadow = item.isShadow;
				this.shadowIcon = item.shadowIcon;
				this.shadowIconText = item.shadowIconText;
			}
		}
	}
</script>

<style scoped lang="less">
.shade{
	position: fixed;
	top: 0px;
	left: 0px;
	z-index: 9999;
	height: 100vh;
	width: 100vw;
	background: rgba(0, 0, 0, 0.5);
	.shade-box{
		position: relative;
		background: #FFF;
		padding: 10px;
		border-radius: 10px;
		img{
			width: 50vw;
			max-width: 360px;
			height: 50vw;
			max-height: 360px;
		}
		.close{
			position: absolute;
			right: -36px;
			top: -36px;
			color: #FFFFFFCC;
			height: 30px;
			width: 30px;
			font-size: 30px;
		}
	}
}
</style>
<style>
	#app {
		/* font-family: Avenir, Helvetica, Arial, sans-serif; */
		text-align: center;
		/* -webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: #2c3e50; */
	}
	.el-carousel__indicators--horizontal{
		bottom: 4% !important;
	}
	.el-carousel__arrow {
		width: 68px !important;
		height: 68px !important;
	}
	
	.el-carousel__arrow i {
		font-size: 54px !important;
		font-weight: 400;
	}
	
	
	.btn button{
		max-width: 116px;
		min-width: 88px;
		margin: 10px;
		line-height: 10px;
		border-radius: 2px;
		color: #fff !important;
		background-color: #008cd6 !important;
		border-color: #008cd6 !important;
	}
	.el-button:focus,
	.btn button:hover {
		color: #fff !important;
		background-color: #008cd6 !important;
		border-color: #008cd6 !important;
	}

	nav {
		padding: 30px;
	}

	nav a {
		font-weight: bold;
		color: #2c3e50;
	}

	nav a.router-link-exact-active {
		color: #42b983;
	}
</>
