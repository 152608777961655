import axios from "axios";

// // axios.defaults.baseURL = "192.168.10.132"
// const request = axios.create({
// 	baseURL: "https://apitest.tskjgroup.com"
// 	// baseURL: "https://api.tskjgroup.com"
// });

const pro_baseURL = "https://api.tskjgroup.com";
// const dev_barseURL = "http://abnor.tskjgroupapi/";
const dev_barseURL = "https://api.tskjgroup.com";
const axios_barseURL = process.env.NODE_ENV == 'production' ? pro_baseURL : dev_barseURL;

// axios.defaults.baseURL = "192.168.10.132"
const request = axios.create({
    // baseURL: 'https://api.tskjgroup.com'
    baseURL: axios_barseURL
});

// //请求拦截器
request.interceptors.request.use(
	config => config,
	err => Promise.reject(err)
);

//响应拦截器
request.interceptors.response.use(
	res => {
		// console.log('响应拦截器', res)
		let status = res.status;
		if (status == 200) {
			return res.data;
		}
	},
	err => Promise.reject(err)
);
// 错误拦截


export default request;
export const get = (url, params) => {
	return request({ url, params, method: 'GET' })
}
export const post = (url, params) => {
	return request({ url, params, method: 'POST' })
}
export const put = (url, params) => {
	return request({ url, params, method: 'PUT' })
}
export const del = (url, params) => {
	return request({ url, params, method: 'DELETE' })
}
