import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		nowRouter: "",			// 当前路由(全)
		nowFatherRouter: "",	// 当前路由(主路由)
		nowRouterQuery: {},		// 当前路由参数

		isGrey: 0,			// 重大时间置灰 1：置灰
		signs: {},			// sign
		footer: "",			// 页脚数据
	},
	getters: {},
	mutations: {
		setSigns(state, payload){
			// console.log("signs改变1", state, payload)
			let v = state.signs[payload.name]
			if(v != payload.sign){
				// console.log("signs改变", state.signs)
				// state.signs[payload.name] = payload.sign;
				state.signs = {...state.signs, [payload.name]: payload.sign};
				localStorage.setItem('tsjt-signs', JSON.stringify(state.signs));
			}
		},
		getSigns(state){
			state.signs = JSON.parse(localStorage.getItem('tsjt-signs'));
		},
		setFooter(state, payload){
			state.footer = payload;
		},
		setIsGrey(state, payload){
			// console.log('payload',payload)
			state.isGrey = payload;
		}
		
	},
	actions: {},
	modules: {}
})
